/* eslint-disable no-prototype-builtins */
import PWskillsBot from 'public/images/Icons/PWskillsBot.svg';
import ChatWithUs from 'public/images/Icons/ChatWithUs.svg';
import CloseBot from 'public/images/Icons/CloseBot.svg';
import ConnectOnWhatsApp from 'public/images/Icons/ConnectOnWhatsApp.svg';
import Image from 'next/image';
import React, { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Typography } from '@pwskills/rachnaui';
import useMiscellaneousStore from '@/store/useMiscellaneousStore';
import { ChatbotType, MiscellaneousEnums } from '@/GlobalEnums/enums';

// import useSlugTitleMapFunction from '@/store/useSlugTitleMap';
import useKapture from '@/hooks/useKapture';
import useUserDetails from '@/store/userDetails';

const SKillsBot = () => {
  const router = useRouter();
  const { handleKaptureScript, removeIframe } = useKapture();
  // const { slugTitleMap } = useSlugTitleMapFunction();
  const [isBotOpen, setIsBotOpen] = useState(false);
  const { setChatbotType, chatBotType } = useMiscellaneousStore();
  const [chatbotState, setChatbotState] = useState(false);
  const { userIsLoggedIn } = useUserDetails();
  const ref: any = useRef();
  const allowedRoutes = [
    '/',
    '/course/[courseSlug]',
    '/category/[categorySlug]',
    '/courses',
    '/online-degree',
    '/degree/[degreeSlug]',
    '/checkout',
  ];
  // useEffect(() => {
  //   if (
  //     (router.query.hasOwnProperty(RouterEnmus.CATEGORY_SLUG) ||
  //       router.query.hasOwnProperty(RouterEnmus.COURSE_SLUG)) &&
  //     slugTitleMap // check is slug or category and slugTitleMap
  //   ) {
  //     if (router.query.hasOwnProperty(RouterEnmus.CATEGORY_SLUG)) {
  //       handleKaptureScript(
  //         localStorage.getItem(ACCESS_TOKEN) ? MiscellaneousEnums.LOGGED_IN : '',
  //         [RouterEnmus.CATEGORY, router.query.categorySlug as string]
  //       );
  //     } else {
  //       handleKaptureScript(isLoggedIn ? MiscellaneousEnums.LOGGED_IN : '', [
  //         RouterEnmus.COURSE,
  //         router.query.courseSlug as string,
  //       ]);
  //     }
  //   } else
  //     handleKaptureScript(
  //       localStorage.getItem(ACCESS_TOKEN) ? MiscellaneousEnums.LOGGED_IN : '',
  //       []
  //     );
  // }, [router.isReady, slugTitleMap]);
  const handleKaptureClick = () => {
    setChatbotType(ChatbotType.KAPTURE);
    if (!chatbotState) handleKaptureScript(userIsLoggedIn ? MiscellaneousEnums.LOGGED_IN : '', ref);
    else removeIframe();
    setChatbotState(!chatbotState);
  };
  const handleBotIconClick = (mode: string) => {
    if (mode === 'close') {
      handleKaptureClick();
      setChatbotType('');
    }
    setIsBotOpen(!isBotOpen);
  };
  const handleWhatsAppClick = () => {
    setChatbotType(ChatbotType.WHATSAPP);
    window.open('https://wa.me/8448982597?text=Hi%20there', '_blank');
  };
  return (
    <div className="fixed bottom-4 right-10 z-50 " id="parent-div">
      {allowedRoutes.includes(router.route) && (
        <div
          className="flex flex-col items-end justify-center gap-3 relative"
          ref={ref}
          id="child-div"
        >
          {isBotOpen && chatBotType !== ChatbotType.KAPTURE && (
            <>
              <button
                type="button"
                className="w-[150px] p-3 bg-[#171214] rounded-full flex items-center justify-end gap-3 animate-chatbot"
                onClick={handleKaptureClick}
              >
                <Typography component="body-small" variant="bold" className="text-white">
                  Chat with us
                </Typography>
                <Image src={ChatWithUs} alt="bot" />
              </button>
              <button
                type="button"
                className="w-[150px] p-3 bg-[#171214] rounded-full flex items-center justify-end gap-3"
                onClick={handleWhatsAppClick}
              >
                <Typography component="body-small" variant="bold" className="text-white">
                  Connect On
                </Typography>
                <Image src={ConnectOnWhatsApp} alt="bot" />
              </button>
            </>
          )}
          {isBotOpen ? (
            <button type="button" onClick={() => handleBotIconClick('close')}>
              <Image src={CloseBot} alt="bot" height={55} width={55} />
            </button>
          ) : (
            <button type="button" onClick={() => handleBotIconClick('open')}>
              <Image src={PWskillsBot} alt="bot" height={55} width={55} />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default SKillsBot;
