/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
import { callKaptureAPI } from '@/api/kapture';
import { BooleanValue, KaptureEnums, MiscellaneousEnums } from '@/GlobalEnums/enums';
import { IUserProfileDetails } from '@/interfaces/common';
import {
  // environment,
  kaptureChatbotScript,
  kaptureDataSupportKey,
} from '@/utils/common/env.constants';
import { getCookies } from '@/utils/helper/helperFunction';
import { useRouter } from 'next/router';

export default function useKapture() {
  const router = useRouter();
  const allowedRoutes = [
    '/',
    '/course/[courseSlug]',
    '/category/[categorySlug]',
    '/courses',
    '/online-degree',
    '/degree/[degreeSlug]',
    '/checkout',
  ];

  const kaptureCustomerAPI = async (userDetails: IUserProfileDetails) => {
    const payload = {
      contact_person_name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      contact_person_phone: userDetails?.phoneNumber,
      contact_person_email: userDetails?.email,
      customer_code: userDetails?._id,
    };
    /* eslint-disable no-console */
    try {
      await callKaptureAPI(payload);
    } catch (error) {
      // Handle the error as needed, e.g., show a notification to the user
    }
  };

  const removeIframe = () => {
    const iframe = document.getElementById('kaptureChatIframe');
    if (iframe) {
      iframe?.parentNode?.removeChild(iframe);
    }
  };
  const handleKaptureScript = (mode: string, ref: any) => {
    const iframe = document.getElementById('kaptureChatIframe');
    if (iframe) {
      iframe?.parentNode?.removeChild(iframe);
    }
    if (kaptureChatbotScript === BooleanValue.TRUE && allowedRoutes.includes(router.route)) {
      // Create a new script element with additional attributes
      const newIframe = document.createElement('iframe');
      if (newIframe) {
        newIframe.setAttribute(
          'style',
          'visibility: visible; opacity: 1; transform: translateY(0px); transition: 0.3s; position: fixed; right: 30px; bottom: 100px; z-index: 99999; font-family: Poppins, sans-serif; width: 30%; height: 70%; border-radius: 15px;'
        );
        newIframe.id = 'kaptureChatIframe';
        newIframe?.setAttribute('data-supportkey', kaptureDataSupportKey as string);
        newIframe.setAttribute('chat-for', 'TICKET');
        newIframe.setAttribute('data-server', 'Indian');
        let src = '';
        if (mode === MiscellaneousEnums.LOGGED_IN) {
          src = `https://selfserveapp.kapturecrm.com/cb-v1/web-view/webview_chat.html?&data-supportkey=${kaptureDataSupportKey}&chat-for=TICKET&data-server=Indian&server-host=ms-noauth&script_type=RNF&customer_code=${getCookies(
            KaptureEnums.KAPTURE_CC
          )}&iv=${getCookies(KaptureEnums.KAPTURE_IV)}`;
        } else
          src = `https://selfserveapp.kapturecrm.com/cb-v1/web-view/webview_chat.html?&data-supportkey=${kaptureDataSupportKey}&chat-for=TICKET&data-server=Indian&server-host=ms-noauth&script_type=NR`;
        newIframe.src = src;
        ref.current.appendChild(newIframe);
      }
    }
  };
  return {
    kaptureCustomerAPI,
    handleKaptureScript,
    removeIframe,
  };
}
